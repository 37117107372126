/** @jsxRuntime classic */
/** @jsx jsx */
import React, { Dispatch, SetStateAction } from "react"
import { Flex, Box, Field, jsx } from "theme-ui"

import type { SearchProps } from "pages/find-a-retailer"

import AguilaButton from "components/AguilaButton"

interface RetailerLocatorProps {
  //enables sx prop to be passed down from parent
  className?: string
  search: SearchProps
  setSearch: Dispatch<SetStateAction<SearchProps>>
  onSubmit: (e: React.FormEvent) => void
  useLocation: Dispatch<SetStateAction<boolean>>
  loading: boolean
}

const RetailerLocatorFull = ({
  className,
  search,
  setSearch,
  onSubmit,
  useLocation,
  loading = true,
}: RetailerLocatorProps) => {
  const handleDistanceChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSearch({ ...search, distance: parseInt(e.target.value) })
  }

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch({ ...search, text: e.target.value })
  }

  //   const useMyLocation = () => {}

  return (
    <div className={className}>
      <Flex
        as="form"
        sx={{
          alignItems: "center",
          position: "relative",
          color: "white",
          zIndex: 10,
          bg: "secondary",
          p: [4, null, null, 8],
          boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.2)",
          width: "100%",
          flexWrap: ["wrap", null, null, "nowrap"],
        }}
      >
        <Flex
          sx={{
            flex: ["1 100%", null, null, "1 80%"],
            mr: 5,
            flexDirection: ["column", "row"],
            // flexWrap: ["wrap", null, "nowrap"],
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              mb: [4, null, null, 0],
              mr: [7, null, null, 10],
              flex: ["0 1 70%", null, null, "1 1 50%"],
            }}
          >
            <Field
              as="input"
              type="text"
              name="search"
              label="Enter your city & state or ZIP code"
              value={search.text}
              onChange={handleSearchChange}
              disabled={loading}
              sx={{
                variant: "forms.input",
                width: "100%",
                height: 31,
                mb: 2,
                color: "white",
                borderColor: "white",
              }}
            />
          </Box>
          <Box
            sx={{
              position: "relative",
              width: ["100%", null, null, "160px"],
              ml: [0, null, null, 5],
              mb: [4, null, null, 0],
              mr: [0, null, null, 10],
              flex: ["0 1 30%", null, null, "1 1 auto"],
            }}
          >
            <Field
              as="select"
              name="distance"
              label="Distance"
              sx={{
                variant: "forms.select",
                width: "100%",
                //   maxWidth: "350px",@
                mb: 2,
                color: "white",
                backgroundColor: "secondary",
                borderColor: "white",
              }}
              onChange={handleDistanceChange}
              value={search.distance}
              disabled={loading}
            >
              <option value="10">10 miles</option>
              <option value="15">15 miles</option>
              <option value="25">25 miles</option>
              <option value="50">50 miles</option>
              <option value="100">100 miles</option>
            </Field>
          </Box>
        </Flex>
        <AguilaButton
          variant="secondary"
          text="Find a retailer"
          sx={{ flex: "1 0 auto", mb: 2, background: "transparent" }}
          callback={onSubmit}
        />
      </Flex>
      {loading && (
        <p
          sx={{
            mt: 18,
            mx: "auto",
            display: "block",
            width: "100%",
            textAlign: "center",
          }}
        >
          Loading...
        </p>
      )}
    </div>
  )
}

export default RetailerLocatorFull
