/** @jsxRuntime classic */
/** @jsx jsx */
import L from "leaflet"
import { FeatureGroup, Marker, Popup, useMap } from "react-leaflet"
import { jsx, Flex } from "theme-ui"
import { useEffect, useMemo } from "react"

import type { LocationProps } from "pages/find-a-retailer"

const generateMarker = (index: number) => {
  const marker = L.divIcon({
    className: "div-icon",
    html: `<div class='pin'><span class='middle'></span></div>`,
    iconSize: [30, 42],
    iconAnchor: [15, 42],
  })

  return marker
}

const Locations = ({ locations }: { locations: LocationProps[] }) => {
  const map = useMap()
  const bounds = useMemo(() => {
    const b: any[] = []
    locations.map(location => {
      b.push([location._geoloc.lat, location._geoloc.lng])
    })
    return b
  }, [locations])

  useEffect(() => {
    if (bounds.length > 0) {
      setTimeout(() => {
        // map.invalidateSize()
        map.fitBounds(bounds)
        if (bounds.length < 4) {
          map.zoomOut()
        }
      }, 201)
    }
  }, [bounds])

  useEffect(() => {
    setTimeout(() => {
      map.invalidateSize()
    }, 200)
  }, [locations])

  return (
    <FeatureGroup>
      {locations.map((location, i) => (
        <Marker
          key={location.objectID}
          position={[location._geoloc.lat, location._geoloc.lng]}
          icon={generateMarker(i)}
        >
          <Popup>
            <strong sx={{ textTransform: "uppercase" }}>{location.Name}</strong>
            <br />
            {location["Street Address"]}
            <br />
            {location.City} {location.State}, {location.Zip}
            <Flex>
              <a
                sx={{ color: "lightTan", textDecoration: "none" }}
                href={`tel:${location.Phone}`}
              >
                {location.Phone}
              </a>
              <span sx={{ mx: 2 }}>|</span>
              <a
                target="_blank"
                rel="noreferrer noopener"
                sx={{ color: "lightTan", textDecoration: "none" }}
                href={location.url}
              >
                Visit Website
              </a>
            </Flex>
          </Popup>
        </Marker>
      ))}
    </FeatureGroup>
  )
}

export default Locations
