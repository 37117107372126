/** @jsx jsx */
import React, { Dispatch, useEffect, useState } from "react"
import ReactLeafletGoogleLayer from "react-leaflet-google-layer"
import { Container, Flex, Box, Heading, Paragraph, jsx } from "theme-ui"
import { MapContainer, useMap } from "react-leaflet"
import { PageProps } from "gatsby"
import "leaflet/dist/leaflet.css"

import Layout from "components/Layout"
import Locations from "components/Map/Locations"
import PageHero from "components/PageHero"
import RetailerLocatorFull from "components/Locator/RetailLocatorFull"

const mapHeight = 800

const getMiles = (n: number) => {
  return Math.ceil(n * 0.000621371192)
}

interface RetailLocatorProps extends PageProps {
  className?: string
}

export interface SearchProps {
  text: string
  latlng: {
    lat: number | string
    long: number | string
  }
  distance: number
}

export interface LocationProps {
  objectID: string
  Name: string
  City: string
  State: string
  Zip: string
  Phone: string
  url: string
  "Street Address": string
  _geoloc: {
    lat: number
    lng: number
  }
  _rankingInfo: {
    matchedGeoLocation: {
      distance: number
    }
  }
}

const Tile = () => {
  const map = useMap()

  useEffect(() => {
    map.invalidateSize()
  }, [])

  return (
    <ReactLeafletGoogleLayer
      apiKey={process.env.GATSBY_MAP_API}
      type={"roadmap"}
    />
  )
}

const RetailLocator = ({ className, ...props }: RetailLocatorProps) => {
  const [locations, setLocations] = useState<LocationProps[]>([])
  const [search, setSearch] = useState<SearchProps>({
    text: "",
    latlng: { lat: "", long: "" },
    distance: 50,
  })
  const [searching, setSearching] = useState(false)

  const [useLocation, setUseLocation] = useState(false)

  useEffect(() => {
    if (useLocation) {
      navigator.geolocation.getCurrentPosition(position => {
        getResults(
          {
            ...search,
            latlng: {
              lat: position.coords.latitude,
              long: position.coords.longitude,
            },
          },
          setLocations
        )
      })
      setUseLocation(false)
    }
  }, [useLocation])

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const searchString = params.get("search")
    if (searchString !== null) {
      const newSearch = { ...search, text: searchString }
      setSearch(newSearch)
      getResults(newSearch, setLocations)
    }
  }, [])

  useEffect(() => {
    if (searching) {
      setSearching(false)
    }
  }, [locations])

  const getResults = async (search: SearchProps, setter: Dispatch<[]>) => {
    const url = `${process.env.GATSBY_ROOT_URL}/api/map`

    const data = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(search),
    })

    const json = await data.json()
    setter(json.hits)
  }

  const handleSearch = async (e: React.FormEvent) => {
    e.preventDefault()
    setSearching(true)
    getResults(search, setLocations)
  }

  const onMouseEnter = (i: number) => {
    const element = document.querySelectorAll(
      `.div-icon:nth-child(${i + 1}) .middle`
    )[0] as HTMLElement
    element.style.backgroundColor = "#E1251B"
  }

  const onMouseLeave = (i: number) => {
    const element = document.querySelectorAll(
      `.div-icon:nth-child(${i + 1}) .middle`
    )[0] as HTMLElement
    element.style.backgroundColor = "white"
  }

  return (
    <Layout
      seo={{
        metaTitle: "Find a Retailer",
        // metaDescription: "News & Events Page",
      }}
      location={props.location}
    >
      <PageHero
        title="find a retailer"
        buttonOneText="Online Retailers"
        buttonOneUrl="/online-retailers"
        buttonOneVariant="textOnly"
        extraButtonInfo="Shopping Online?"
      />
      <Container sx={{ mt: ["-100px", null, "-180px"] }}>
        <RetailerLocatorFull
          search={search}
          setSearch={setSearch}
          sx={{ mb: 18, maxWidth: ["100%", null, "80%"], mx: "auto" }}
          onSubmit={handleSearch}
          loading={searching}
          useLocation={setUseLocation}
        />
      </Container>
      <Flex
        sx={{
          height: ["auto", null, mapHeight],
          overflow: "hidden",
          flexWrap: ["wrap", null, "nowrap"],
          minHeight: "300px",
          display: [
            locations.length < 1 ? "none" : "block",
            locations.length < 1 ? "none" : "block",
            "flex",
            null,
            null,
          ],
        }}
      >
        <Box
          sx={{
            ml: [
              0,
              null,
              locations.length > 0 ? 0 : -300,
              locations.length > 0 ? 0 : -600,
            ],
            overflowY: "scroll",
            backgroundColor: "text",
            color: "white",
            width: [
              "100%",
              null,
              locations.length > 0 ? 300 : 0,
              locations.length > 0 ? 600 : 0,
            ],
            transition: "all 0.2s",
          }}
        >
          <ol
            sx={{
              m: 0,
              py: locations.length > 0 ? 8 : 0,
              px: locations.length > 0 ? 12 : 0,
              listStyle: "none",
              counterReset: "locations-counter",
            }}
          >
            {locations.map((location, i) => (
              <Flex
                as="li"
                key={location.objectID}
                sx={{
                  m: 0,
                  mb: 2,
                  py: 2,
                  flexWrap: "wrap",
                  borderBottom: "1px solid white",
                  counterIncrement: "locations-counter",
                }}
                onMouseEnter={() => onMouseEnter(i)}
                onMouseLeave={() => onMouseLeave(i)}
              >
                <Box sx={{ flex: "1 100%" }}>
                  <Heading as="h1">
                    <span
                      sx={{
                        "&:before": {
                          width: 35,
                          height: 35,
                          border: "2px solid",
                          borderColor: "secondary",
                          borderRadius: "100%",
                          fontSize: 2,
                          content: "counter(locations-counter)",
                          mb: 2,
                          mr: 3,
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                        },
                      }}
                    />
                    <span sx={{ fontSize: 2 }}>{location.Name}</span>
                  </Heading>
                </Box>
                <Box sx={{ flex: "1 70%" }}>
                  <Paragraph sx={{ fontSize: 1, m: 0, color: "lightTan" }}>
                    {getMiles(
                      location._rankingInfo.matchedGeoLocation.distance
                    )}{" "}
                    miles
                  </Paragraph>
                  <Paragraph sx={{ fontWeight: "bold", lineHeight: 1.75 }}>
                    {location["Street Address"]} <br />
                    {location.City} {location.State}, {location.Zip}
                  </Paragraph>
                </Box>
                <Box sx={{ flex: "1 30%" }}>
                  <a
                    sx={{ color: "lightTan", textDecoration: "none" }}
                    href={`tel:${location.Phone}`}
                  >
                    {location.Phone}
                  </a>
                  <br />
                  <a
                    target="_blank"
                    rel="noreferrer noopener"
                    sx={{
                      color: "lightTan",
                      textDecoration: "underline",
                      textDecorationColor: "secondary",
                    }}
                    href={location.url}
                  >
                    Visit Website
                  </a>
                </Box>
              </Flex>
            ))}
          </ol>
        </Box>
        <Box
          sx={{
            display: ["none", null, "block"],
            flex: "1 66%",
            ".pin": {
              width: "35px",
              height: "35px",
              borderRadius: "50% 50% 50% 0",
              background: "#E1251B",
              position: "absolute",
              transform: "rotate(-45deg)",
              left: "50%",
              top: "50%",
              margin: "-20px 0 0 -20px",
            },
            ".middle": {
              content: '""',
              width: "25px",
              height: "25px",
              margin: "5px 0 0 5px",
              background: "white",
              position: "absolute",
              borderRadius: "50% 50% 50% 0",
            },
          }}
        >
          {typeof window !== "undefined" && (
            <MapContainer
              center={[31.51073, -96.4247]}
              zoom={3}
              style={{ height: `${mapHeight}px`, width: "100%" }}
              scrollWheelZoom={false}
            >
              <Tile />
              <Locations locations={locations} />
            </MapContainer>
          )}
        </Box>
      </Flex>
    </Layout>
  )
}

export default RetailLocator
